.container{
    display: flex;
    margin-bottom: 30px;
}

.container > div {
    flex-grow: 1;
}

select{
    width: 90%;
    border-radius: 6px;
    border: 1px solid #00AC97;
    height: 100%;
    box-shadow: 0px 3px 6px #9d9d9d;
}

select:focus{
    outline: none;
}


.input_container{
    box-shadow: 0px 3px 6px #9d9d9d;
    border: 1px solid #00AC97;
    border-radius: 6px;
}

.input_group{
    display: flex;
}

.input_group input{
    border-radius: 6px;
    width: 100%;
    border: white;
}

.input_group input:focus {
    outline: none;
  }

.input_group button{
    border-radius: 6px;
    border: none;
    background-color: white;
    width: 50px;
}

.input_group button:hover{
    background-color: white;
}
