.container {
    margin-top: 100px;
    display: grid;
    place-items: center;
    height: 100%;
}

.logo{
    font-weight: bold;
    font-size: 30px;
    color: #15ae9a;
}
.logo:hover{
    cursor: pointer;
}

.guide{
    margin: 10px 0 40px 0;
    font-size: 19px;
    font-weight: bold;
}

.icon{
    margin-left: 15px;
    font-size: 25px;
    height: 100%;
}

.id_container{
    margin-bottom: 17px;
    display: flex;
    width: 460px;
    height: 60px;
    border-radius: 10px;
    outline: 1px solid #15ae9a;
}
.id{
    margin-left: 7px;
    border: none;
    width: 100%;
    padding-left: 11px;
}
.id:focus{
    outline: none;
}

.timer{
    margin-left: -365px;
    margin-bottom: 10px;
}

.password{
    margin-left: 7px;
    height: 80%;
}


.send_authcode_btn{
    display: flex;
    margin-bottom: 17px;
    display: flex;
    width: 460px;
    height: 60px;
    border-radius: 10px;
    background-color: #15ae9a;
    color: white;
    font-weight: bold;
    font-size: 17px;
    justify-content: center;
    align-items: center;
}

.send_authcode_btn:hover{
    cursor: pointer;
}

p{
    display: flex;
    align-items: center;
    color: black;
}