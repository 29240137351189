.mainContainer{
    background-color: #454545;
}
.subContainer{
    height: 180px;
    max-width: 1150px;
    margin: auto;
}


.content{
    margin: left;
}

h3{
    color: rgb(205, 205, 205);
    padding-top: 10px;
}

p{
    color: #bcbcbc;
    padding: 0;
    margin: 0;
}

.info{
    padding-top: 10px;
}