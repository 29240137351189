.paginationContainer{

    text-align: center;
}
.pagination {
    margin-bottom: 20px;
    display: inline-block;
}

.pagination a{
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    border: none;
    border-radius: 8px;
    margin: 0 2px;
    transition: background-color 1s ease, color 1s ease;
}

.pagination a:hover:not(.active) {
    background-color: #00AC97;
    color: white;
}

.pagination a.active {
    background-color: #00AC97;
    color: black;
}