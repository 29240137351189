.body{
    background-color: #f4f5f9;
    padding: 30px 30px;
    height: 80vh;
}

.container {
    background-color: #f4f5f9;
    max-width: 1150px;
    margin:0 auto;
    box-shadow: 0px 3px 8px #9d9d9d;
    background-color: rgb(247, 247, 247);
    border-radius: 8px;
}