.container{
    background-color: #f4f5f9;
    padding: 30px 0;
    height: 80vh;
    align-items: center;
    height: auto;
    display: flex;
    width: 100%;
}

.subContainer{
    background-color: white;
    margin: auto;
    padding: 30px;
    width: 1400px;
    box-shadow: 0px 3px 8px #9d9d9d;
    border-radius: 8px;
    display: flex;
}

.section1{
    width: 50%;
    margin-right: 10px;
}

.section2{
    width: 50%;
    margin-left: 10px;
}



.title{
    border-radius: 5px;
    display: flex;
    height: 50px;
    background-color: #15AE9A;
    margin-bottom: 20px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
}





/* 시설리스트 컨테이너 */
.facilityListCtn{
    height: 90%;    
}

/* 페이지네이션 컨테이너 */
.paginationCtn {
    display: flex;
    justify-content: center;
    align-items: end;
    height: 10%;
}