.body{
    background-color: #f4f5f9;
    padding: 30px 30px;
    min-height: 80vh;
}

.container {
    width: 730px;
    margin:0 auto;
    background-color: #f4f5f9;
}

.postForm{
    background-color: #ffffff;
    box-shadow: 0px 3px 8px #9d9d9d;
    border-radius: 8px;
}

.title{
    height: 60px;
    display: flex;
    align-items: center;
}

.title input{
    width: 100%;
    height: 100%;
    font-size: 22px;
    padding: 20px 20px;
    font-weight: bold;
    border: none;
    background-color: #f4f4f4;
    border-radius: 8px;
}

.title input:focus{
    outline: none;
}

.content{
    width: 100%;
    min-height: 300px;
    border: none;
    padding: 20px 20px;
}

.content:focus{
    outline: none;
}

.btn{
    display: flex;
    justify-content: end;
    margin-top: 20px;
}

.btn button{
    display: flex;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    height: 36px;
    width: 100px;
    justify-content: center;
    align-items: center;
}

.btn button:first-child{
    margin-right: 10px;
    background-color: black;
    color: white;
}

.btn button:first-child:hover{
    background-color: #595959;   
}

.btn button:last-child{
    background-color: #15AE9A;
    color: white;
}

.btn button:last-child:hover{
    background-color: #0f7769;
}