.container{
    background-color: #f4f5f9;
    min-height: 90vh;
    display: flex;
    align-items: center;
}

.star{
    cursor: pointer;
}

.contentContainer{
    padding: 0px;
    min-width: 800px;
    margin: auto;
    border-radius: 12px;
    box-shadow: 0px 3px 8px #9d9d9d;
    background-color: white;
    margin-top: 30px;
    margin-bottom: 30px;
}

.detailFacilityTitle{
    width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #419488;
    color: white;
    font-size: 24px;
    font-weight: bold;
}

.info_table{
    border-top: 3px solid #e0e0e0;
}

.btn_container{
    display: flex;
    justify-content: end;
    padding: 10px
}
.to_main_btn{
    color: white;
    background-color: #419488;
}